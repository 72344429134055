import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, TextField, useTheme } from '@mui/material';
import React, { useState } from 'react';
import UploadDialog from './UploadDialog';
import { IoCheckmarkCircle } from 'react-icons/io5';
import ViewDialog from './ViewDialog';
import { wideTableCellWidth } from '../const/const';
import UserDocDialog from './UserDocDialog';
import { useLocation } from 'react-router-dom';
import { doc } from 'firebase/firestore';
import { IoMdAdd } from "react-icons/io";
import AddRow from './AddRow';
import DocRowForWrite from "./DocRowForWrite"
import uuid from 'react-uuid';
function UploadTablePerTrans({
//   setCustomDocs,
// customDocs,
disable,
rowKey,
fileKey="uploadFiles",
  uploadTable,setUploadTable,type="edit"}) {
//여기서 upIdx가지고 user별 데이터 업데이트 하자
const theme=useTheme()
const location=useLocation()
const rowId= rowKey//type==="write"?"write":location.state.rowKey

const handleUploadTableAdd=()=>{
  setUploadTable(p=>[...p,{name:`new name-${p.length}`,source:`new source-${p.length}`,uploadFiles:Array(5).fill(new File([],"")),thumbnails:Array(5).fill(""),custom:true,applicable:true}])
}
    return (
        <TableContainer component={Paper} variant='outlined'>
      
      <Table
      
      sx={{ minWidth: 500 }} aria-label="custom pagination table">
       <TableHead 
     sx={{
      backgroundColor:theme.palette.info.main,
    
     }}
       >
        <TableCell style={{ width: "100px",  color:'white' }} scope="row" >
                
              </TableCell>
       <TableCell style={{ width: "800px",  color:'white' }} scope="row" >
                Document name
              </TableCell>
              <TableCell style={{ width: "300px",  color:'white' }} scope="row" >
                Source of document
              </TableCell>
              <TableCell style={{ width: "60px",  color:'white' }} scope="row" >
                Uploaded
              </TableCell>
              <TableCell style={{ width: "100px",  color:'white' }} scope="row" >
                Applicable
              </TableCell>
              <TableCell style={{ width: "100px",  color:'white' }} scope="row" >
                Upload
              </TableCell>
              <TableCell style={{ width: "100px",  color:'white' }} scope="row" >
                View
              </TableCell>
              
       </TableHead>
      {uploadTable===undefined||uploadTable===null?<TableBody></TableBody>: 
       <TableBody>
          {uploadTable.map((up,upIdx)=>
          up.name!=""&&up.source!=""&&
          <DocRowForWrite
          disable={disable}
          up={up}
          upIdx={upIdx}
          rowId={rowId}
          setUploadTable={setUploadTable}
          uploadTable={uploadTable}
          />
     )}
       {/* {customDocs.map((cdoc,cdocIdx)=><AddRow cdocIdx={cdocIdx} cdoc={cdoc} rowId={rowId}
       setUploadTable={setUploadTable}
       setCustomDocs={setCustomDocs} />)} */}
      
        </TableBody>}
   
      
      </Table>     <Button
      fullWidth
      onClick={handleUploadTableAdd}
      > 
     <IoMdAdd size={20}/> 
      ADD DOCUMENT NAME / SOURCE OF DOCUMENT </Button>
    </TableContainer>
    );
}

export default UploadTablePerTrans;